
import axios from '../utils/axios'


export function createOrder(params)
{
    return axios.post(`/order`,params)
}

export function payOrder(params)
{
    return axios.post(`/order/pay_order`,params)
}

export function schoolPayment()
{
    return axios.get(`/order/school_payment`)
}

export function orderInfo(query)
{
    return axios.get(`/order/show`,{params:query})
}

export function updateOrder(params)
{
    return axios.put(`/order`,params)
}
