<template>
    <s-header :name='""'></s-header>
    <div style="background:#F6F6F6;width:100%;height:auto;">
        <van-notice-bar color="#EE0A24" background="#ecf9ff" left-icon="info-o">
            报名成功,请牢记以下信息
        </van-notice-bar>
        <van-cell-group>
            <div class="van-cell">
                <div class="van-cell__title">
                    <span>所在考点：</span>
                </div>
                <div class="van-cell__value">
                    {{ state.student.school_name }}
                </div>
            </div>

            <div class="van-cell">
                <div class="van-cell__title">
                    <span>所在班级：</span>
                </div>
                <div class="van-cell__value">
                    {{ state.student.clbum_name }}
                </div>
            </div>

            <div class="van-cell">
                <div class="van-cell__title">
                    <span>考生姓名:</span>
                </div>
                <div class="van-cell__value">
                    {{ state.student.name }}
                </div>
            </div>

            <div class="van-cell">
                <div class="van-cell__title">
                    <span>报名序号:</span>
                </div>
                <div class="van-cell__value">
                    {{ state.student.num }}
                </div>
            </div>
        </van-cell-group>

        <!-- 未完成,还差学校检测判断.... -->
        <div v-if="$route.query.skip == 1">
            <van-notice-bar wrapable color="#EE0A24" background="#ecf9ff" left-icon="info-o">
                说明:黑色为本校测评检测,红色为全省统一全真质检/教学质量检测:
            </van-notice-bar>
            <div v-for="(item, index) in state.hasInfo.examList" :key="index" class="demo-preview">
                <div style="margin-left: 5%">
                    <!-- <h1>{{ state.addId - index }}</h1> -->
                    <h1>{{ item.id }}</h1>
                    <div class="clbum-info">
                        <h3 style="color:#EE0A24">{{ item.name }}-￥{{ item.price }}</h3>
                        <!-- <span v-if="state.student.sex=='0'">男</span><span v-if="state.student.sex=='1'">女</span>- -->
                        <p>检测时间:{{ item.testd_at }}</p>
                    </div>
                </div>
            </div>
            <div style="margin: 10px;">
                <van-button block type="primary" @click="back">
                    <span>返回主页</span>
                </van-button>
            </div>
        </div>

        <div v-if="$route.query.skip == 2">
            <van-notice-bar color="#EE0A24" background="#ecf9ff" left-icon="info-o">
                需补报检测信息
            </van-notice-bar>

            <van-cell-group>
                <div v-for="(item, index) in state.hasInfo.repairExam" :key="index" class="van-cell">
                    <div class="van-cell__title">
                        <span>{{ item.id }}.{{ item.name }}</span>
                    </div>
                    <div class="van-cell__value_1">
                        ￥{{ item.price }}
                    </div>
                </div>

                <div class="van-cell">
                    <div class="van-cell__title">
                        <span>合计:</span>
                    </div>
                    <div class="van-cell__value_1">
                        ￥{{ state.hasInfo.repairExamPrice }}
                    </div>
                </div>
            </van-cell-group>

            <div v-if="state.hasInfo.repairExam && state.hasInfo.repairExam.length > 0" style="margin: 20px 10px;">
                <van-button @click="friendPay" style="width:48%;" plain type="primary" size="normal">
                    <span>亲友/同学/老师代付</span>
                </van-button>
                <van-button @click="onPay()" style="width:48%;margin-left:4%" type="primary" size="normal">
                    <span>微信支付</span>
                </van-button>
            </div>
            <div v-else>
                <div class="van-cell">
                    <div class="van-cell__title">
                        <span>暂无需要补报的检测</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$route.query.skip == 3">
            <van-notice-bar color="#EE0A24" background="#ecf9ff" left-icon="info-o">
                已支付检测信息
            </van-notice-bar>

            <van-cell-group v-for="(item, index) in state.hasInfo.order" :key="index" :title="'订单号-' + item.order_no">
                <div class="van-cell">
                    <div class="van-cell__title">
                        <span>订单号:</span>
                    </div>
                    <div class="van-cell__value">
                        {{ item.order_no }}
                    </div>
                </div>
                <div class="van-cell">
                    <div class="van-cell__title">
                        <span>所选检测:</span>
                    </div>
                    <div class="van-cell__value">
                        {{ item.name }}
                    </div>
                </div>

                <div class="van-cell">
                    <div class="van-cell__title">
                        <span>金额:</span>
                    </div>
                    <div class="van-cell__value">
                        ￥{{ item.price }}
                    </div>
                </div>

                <div class="van-cell">
                    <div class="van-cell__title">
                        <span>时间:</span>
                    </div>
                    <div class="van-cell__value">
                        {{ item.updated_at }}
                    </div>
                </div>
            </van-cell-group>

            <!-- <div v-if="state.student.order_status==0" style="margin: 20px 10px;">
        <van-button @click="back"  style="width:48%;" plain type="primary" size="normal">
            <span style="color:#555555;">返回主页</span>
        </van-button>
        <van-button @click="onPay(state.student.order_no)" style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
            <span >去支付</span>
        </van-button>
    </div> -->

            <div style="margin: 20px 10px;">
                <van-button @click="back" block plain type="primary" size="normal">
                    <span style="color:#555555;">返回主页</span>
                </van-button>
            </div>
        </div>

        <div v-if="$route.query.skip == 4">
            <div v-for="(item, index) in state.grade_list.list" :key="index" class="demo-preview">
                <div>
                    <!-- <h1>{{ state.addId - index }}</h1> -->
                    <!-- <h1>222</h1> -->
                    <div class="clbum-info">
                        <h3 style="color:#EE0A24">{{ item.exam_name }}</h3>
                        <p>素描:{{ item.the_sketch }} 速写:{{ item.sketch }} 色彩:{{ item.color }} 总分:{{ item.total_fen }}
                        </p>
                    </div>
                </div>
            </div>
            <div style="margin: 10px;">
                <van-button block type="primary" @click="back">
                    <span>返回主页</span>
                </van-button>
            </div>
        </div>


    </div>

    <div class="bottom-bg"></div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header'
// import { Toast,Dialog } from 'vant'
import { useRouter, useRoute } from 'vue-router';
import { studentInfo, getJssdk } from '@/apis/student';
import { payOrder } from '@/apis/order';
import { getLocalUser } from '@/utils/function'
import { createOrder } from '@/apis/order';
import { Toast, Dialog } from 'vant';
import wechatInterface from "@/utils/wechatInterface";
import { gradeList } from '@/apis/grade.js'
export default {
    components: {
        sHeader
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const state = reactive({
            student: {},
            grade_list: {},//成绩信息
            hasInfo: {},
            jssdk: {}
        })
        onMounted(async () => {
            console.log(window.location.href);
            state.jssdk = await getJssdk({ url: window.location.href });
            state.student = await studentInfo({ id: route.query.student_id });
            state.hasInfo = state.student.hasInfo;
            if (route.query.skip == 4) {
                state.grade_list = await gradeList({ student_id: state.student.id, sort: 'exam_id', comm_exam: 1 });
            }
            console.log('grade_list', state.grade_list);
            console.log('student', state.student);
            //初始化微信分享
            if (state.student.order_status == 0 && route.query.skip == 2) {//亲友支付初始化
                onShare();
            }
        })

        const onShare = async () => {
            wechatInterface(
                state.jssdk,
                () => {// 分享成功回调
                    console.log('分享成功回调');
                },
                () => {// 分享接口调用失败
                    Dialog.alert({
                        title: '提示',
                        message: '微信分享接口调用失败,如需!!代付!!请点击右上角~刷新~页面重试;刷新后无此提示在进行分享代付!',
                        theme: 'round-button',
                    }).then(() => {
                        // on close
                    });
                },
                {
                    type: 'share',
                    title: '2024美术基础教学质量检测服务费支付',
                    desc: state.student.name + '报名参加2024年美术基础教学质量检测,请你代付服务费.',
                    link: process.env.VUE_APP_WEB_HTTP + '/create-order?student_id=' + state.student.id + '&order_no=' + state.student.order_no + '&dai=qy',
                    imgUrl: process.env.VUE_APP_WEB_HTTP + '/630.png'
                },
            );
        }

        const addOrder = async () => {
            let dataParams = {
                student_id: state.student.id,
                // open_id:user.open_id||getLocalUser('user').open_id,
                open_id: getLocalUser('user').open_id,
                clbum_id: state.student.clbum_id,
                school_id: state.student.school_id,
            }
            let order = await createOrder(dataParams);
            state.student.order_no = order.order_no
        }

        const onPay = async () => {
            Toast.loading({
                message: 'Loading...',
                forbidClick: true,
                duration: 0
            });
            await addOrder();//支付先创建订单
            let result = await payOrder({ order_no: state.student.order_no, open_id: localStorage.getItem('open_id') });//使用当前手机,登录的open_id支付....
            if (typeof result.appId == "undefined") {
                window.location.href = result.payurl;//跳转到易宝收银台支付..
            } else {//微信支付
                wechatInterface(
                    result,
                    () => {// 支付成功回调
                        console.log('支付成功回调');
                        Toast('支付成功');
                        router.push({ path: '/dashboard' })
                    },
                    () => {// 支付失败回调
                        console.log('支付失败回调');
                        Toast('支付失败,请重试');
                    },
                );
                Toast.clear();
            }
        }

        const friendPay = () => {

            Dialog.alert({
                title: '代付须知',
                message: '请点击右上角,将连接发送给亲友支付;如需主考/老师代付,请将报名序号告知主考/老师进行支付.',
                theme: 'round-button',
            }).then(async () => {
                await addOrder();//支付先创建订单
                onShare();//调用微信分享,支持分享代付...
                // onShare();
            });
        }

        const confirm = () => {
            router.push({ path: '/' })
        }

        const back = () => {
            router.push({ path: '/dashboard' })
            // router.go(-1);
        }

        return {
            state,
            friendPay,
            confirm,
            onPay,
            addOrder,
            back,
        };
    },
};
</script>
<style scoped>
.font-color {
    color: #555555;
}

.van-row {
    border-bottom: 1px solid #EBEBEB;
}

.van-col {
    height: 4em;
    line-height: 4em;
}

.bottom-bg {
    height: 0.9rem;
    width: 100%;
    background-color: #F6F6F6;
    border: none;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 8em;
}

.van-cell__value {
    color: #EE0A24;
    width: 70%;
}

.van-cell__value_1 {
    color: #EE0A24;
}

p {
    color: #747474
}

.clbum-info {
    float: left;
    padding-left: 5%;
    font-size: 13px;
    width: 80%;
}

h1 {
    float: left;
    margin-top: 25px;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}

.van-cell__title {
    width: 30%;
}

.van-cell__title,
.van-cell__value {
    flex: auto;
}
</style>