<template>
<s-header :name='"-设置目标成绩"' ></s-header>
<div style="background:#F6F6F6;width:100%;height:100%;">
    <van-notice-bar  wrapable  left-icon="info-o">
      说明:选择检测后,填写或修改该检测你的目标成绩,考后即可自动对比成绩与目标成绩的差距
    </van-notice-bar>

    <van-form @submit="onSubmit" :submit-on-enter="false">
    <van-field
    v-model="state.value"
    readonly
    clickable
    name="picker"
    label="考试"
    placeholder="点击选择检测"
    @click="state.showPicker = true"
    />
    <van-popup v-model:show="state.showPicker" position="bottom">
    <van-picker
        :columns="state.columns"
        @confirm="onConfirm"
        @cancel="state.showPicker = false"
    />
    </van-popup>
    <van-field
        v-model="state.info.the_sketch"
        name="the_sketch"
        label="素描分数"
        type="number"
        placeholder="请填写素描分数"
        :rules="[{ required: true, message: '请填写素描分数' }]"
    />

   <van-field
        v-model="state.info.sketch"
        name="sketch"
        label="速写分数"
        type="number"
        placeholder="请填写速写分数"
        :rules="[{ required: true, message: '请填写速写分数' }]"
    />

    <van-field
        v-model="state.info.color"
        name="color"
        label="色彩分数"
        type="number"
        placeholder="请填写色彩分数"
        :rules="[{ required: true, message: '请填写色彩分数' }]"
    />

    <van-field
        v-model="state.info.exam_id"
        label="考试id"
        name="exam_id"
        v-show="false"
    />

    <van-field
        v-model="state.info.exam_name"
        label="考试名称"
        name="exam_name"
        v-show="false"
    />

   <div style="margin: 20px 10px;">
        <van-button @click="back"  style="width:48%;" plain type="primary" size="normal">
            <span style="color:#555555;">返回主页</span>
        </van-button>
        <van-button style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
            <span >提交</span>
        </van-button>
    </div>
  </van-form>

   <div>
        <van-notice-bar  left-icon="info-o">
          已设置的目标的成绩:
        </van-notice-bar>
        <div v-for="(item, index) in state.list" :key="index" class="demo-preview">
          <div style="margin-left: 5%">
            <!-- <h1>{{ state.addId - index }}</h1> -->
            <div class="clbum-info">
              <h3>{{item.exam_name}}</h3>
              <p>素描:{{item.the_sketch}} 速写:{{item.sketch}} 色彩:{{item.color}}</p>
            </div>
          </div>
          <div class="clbum-share">
            <van-button
              @click="onInfo(item.exam_id)"
              type="primary"
              style="margin-right: 10px"
              plain
              size="small"
              >修改</van-button
            >
          </div>
        </div>
    </div>
    <br/>
</div>

</template>
<script>
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import sHeader from '@/components/Header';
import {examList} from '@/apis/exam';
import {addStageGoal,stageGoalList,stageGoalInfo} from '@/apis/student';
import { Toast } from 'vant';
export default {
   components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      value: '',
      showPicker: false,
      list:{},
      exam_list:{},
      columns:[],
      info:{
        the_sketch:'',
        sketch:'',
        color:'',
        exam_id:'',
        exam_name:''
      }
    });
    onMounted(async()=>{
      state.exam_list = await examList({search:1});
      console.log(state.exam_list[0]);
      for(let i = 0; i < state.exam_list.length; i++){
            console.log( state.exam_list[i]);

            state.columns.push(state.exam_list[i]);
      }
      state.list = await stageGoalList();

      console.log(state.exam_list);
      console.log(state.list);
    })
    const onSubmit = async (values) => {
      console.log(state.info);
      console.log(values);
      await addStageGoal(state.info);//有新的阶段目标编辑,没有新增
      Toast('操作成功');
      router.go(0);
    };

    const onConfirm = async(value) => {
      state.info.exam_id = value.id;
      state.info.exam_name = value.text;
      state.value = value.text;
      let info  = await stageGoalInfo({exam_id:value.id});
      console.log(info);
      if(typeof info!=="undefined"){
        state.info = info;
      }else{
        state.info = {
          the_sketch:'',
          sketch:'',
          color:'',
          exam_id:value.id,
          exam_name:value.text
        }
      }
      state.showPicker = false;
    };

    const onInfo=(async(id)=>{
      let info  = await stageGoalInfo({exam_id:id});

      if(typeof info!=="undefined"){
        state.info = info;
        state.value = info.exam_name;
      }else{
        Toast('数据错误');
      }
    })

    const back=()=>{
        router.go(-1);
    }
    return {
      state,
      onSubmit,
      onConfirm,
      onInfo,
      back
    };
  },
};
</script>
<style  scoped>
.clbum-info {
  float: left;
  margin-left: 5%;
  font-size: 13px;
  width: 80%;
}
.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 10em;
}
.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>