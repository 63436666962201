<template>
    <title>隐私政策协议</title>
    <h2 style="margin:auto;text-align: center;">隐私协议</h2>
    <div style="padding-left: 5%;padding-right: 5%;">
        <p>
            <span style="text-wrap: nowrap;">更新时间：2023年6月10日</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">生效时间：2022年8月1日</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">美术教学质量检测考务系统隐私政策</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">美术教学质量检测考务系统(bmweb.yszytb.com)及其涉及到的产品、相关软件的所有权和运作权归艺联时代教育科技有限公司(以下简称艺联时代)所有，美术教学质量检测考务系统尊重并保护所有美术教学质量检测考务系统用户的个人信息及隐私安全，请您在使用美术教学质量检测考务系统之前，仔细阅读并理解美术教学质量检测考务系统隐私政策。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">我们将尽量采用通俗易懂的文字向您解释本政策所涉及的技术词汇，以便于您的理解。请在使用/继续使用我们的各项产品及服务前，仔细阅读并完全理解本政策中的全部内容，并在需求时，按照本政策指引，自主进行适当的选择。如果您不同意本政策的内容，我们将无法为您提供完整的产品和服务，同时您也需要立即停止使用美术教学质量检测考务系统产品及相关服务。如您开始使用我们的产品或服务，则视为您已经充分、完全地阅读并理解了本政策中的全部内容，并且同意我们按照本政策的约定收集、使用、存储及对外提供您的个人信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">一、我们如何收集和使用您的个人信息</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、您需授权我们收集和使用您的个人信息的情形</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">在您使用美术教学质量检测考务系统及相关服务时，您理解并同意，我们将根据下列不同场景下的相应目的收集、使用您特定种类的个人信息，用以向您提供服务、提升我们的服务质量、保障您的账户安全，以及符合国家法律法规及相关监管的要求。本应用包含定向推送或广告精准营销功能。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(1)注册登录</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">在您注册/登录账户时，您需要提供手机号码及验证码，使用App服务时，您也可使用手机号码一键登录功能，登录时我们还需要收集您的硬件序列号、唯一设备识别码(android_id、Mac地址、IMEI、IMSI)以验证账号及密码并完成登录;如您拒绝提供手机号码及验证码信息，则可能无法成功注册/登录账户。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">当您使用一键登录功能在执行账号注册或登录，请根据您的手机运营商仔细查阅《天翼账号服务与隐私协议》、《联通统一认证服务条款》或《中国移动认证服务条款》，如你执行登录操作我们视您已经阅读并同意运营商相关上述《条款》与本《隐私政策》，您也可以在一键登录服务的页面中选择查阅本《隐私政策》及您手机号所对应的运营商相关协议。经过您的明示同意，运营商会将您的手机号码发送给我们，便于我们为您提供快捷的登录服务。您也可以自主选择手动填写手机号码的方式完成注册或登录。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(2)进入首页</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">在您进入首页时，我们将收集您的高考省份、高考年份、所在高中等信息，以正确提供符合您所在省份的高考信息、历年分数、历年批次线、招生计划、大学、相关高考资讯等信息，如您不提供前述信息，则无法进入首页或使用我们提供的各项服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(3)安全管理</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">为了尽最大努力保护您的账户及交易安全，保障您的账户不被异常使用或盗用，我们会收集您使用美术教学质量检测考务系统服务的相关操作信息，包括您的硬件设备型号、您所使用的软件版本信息、您的IP地址、您所在的位置、唯一设备识别码(android_id、Mac地址、IMEI、IMSI、应用安装列表、IDFA)移动网络信息、标准网络日志数据，如您不同意我们记录或收集前述信息，可能无法完成风控验证。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、通过系统权限收集和使用您的个人信息的情形</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(1)为给您提供上传/修改头像及上传问题反馈的截图的功能，我们会申请访问您手机摄像头/相册的权限。如果您不同意我们的访问申请，您可能无法使用上述服务，但不影响您使用我们提供的其他服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(2)为给您提供您所在高考省份下相关内容，我们会申请获取您的位置权限和传感器权限，我们将基于位置信息的来判断您所在的省份的高考内容信息。如果您不同意我们的获取申请，您可能无法使用上述服务，但不影响您使用我们提供的其他服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(3)为了让您更快捷方便拨打学校招办电话及联系客服，我们会申请授权您的电话/拨号权限，给我们自动填入客服电话号码。如果您不同意我们的授权，您可能无法使用上述服务，但不影响您使用我们提供的其他服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(5)为方便您及时收到最新的高考政策与数据更新相关通知，您可以在使用App时授权我们允许通知的权限，如果您不想收到通知推送或进行授权，您可以直接选择不允许或者在手机设置中关闭通知开关。如果您关闭了消息通知开关，则可能无法收到任何资讯内容推送，但不影响您使用我们提供的其他服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（6）我们会根据您关注的信息，通过消息推送相关内容和服务，您也可以在【个人中心-设置-通知设置】内关闭推送服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（7）在后台状态下我们会获取设备标识符(包括不限于Android ID)，用于识别唯一用户，保证信息的准确统计。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（8）app在前台运行时会获取粘贴板内容，用于用户快捷粘贴相关信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">（9）为了识别唯一用户，保证信息的准确统计，我们将获取您的设备Mac地址、唯一设备标识符（例如IMEI、AndroidID、ICCID、IMSI）</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">（10）自启动和关联启动说明：（1）为确保本应用处于关闭或后台运行状态下可正常按收到客户端推送的广播信息，本应用须使用(自启动）能力，将存在一定频率通过系統发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。（2）当您打开美术教学质量检测考务系统PP内容类推送消息，在征得您的明确同意后，会跳转美术教学质量检测考务系统APP打开相关内容。在未征得您同意的情况下，则不会有关联启动。（3）当您打开美术教学质量检测考务系统APP内部下载的文件后，会关联启动第三方APP。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">二、隐私政策SDK说明</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、第三方插件使用情况</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(1)关键术语定义：设备信息：设备标识符(IMEI、IDFA、AndroidID、MAC、OAID等相关信息)应用信息(应用崩溃信息、通知开关状态、软件列表等相关信息)、设备参数及系统信息(设备类型、设备型号、操作系统及硬件相关信息)网络信息：IP地址，WiFi信息，基站信息等相关信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(2)单独的某一设备信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合识别特定自然人身份，或将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除非取得您的授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(3)为了给您提供更专业、安全的核心业务功能和附加业务功能，我们的产品中会接入第三方插件产品(包括在我们的网页或应用程序中部署统计分析工具、软件开发工具包SDK、调用地图API接口)。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、通过第三方SDK手机与使用您的个人信息披露如下：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(1)百度定位SDK</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">SDK名称: 百度Android定位SDK</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">SDK提供者名称: 北京百度网讯科技有限公司</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">SDK提供者官网: https:///lbs.baidu.com/</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">功能描述：我们收集您的设备信息、位置信息以及网络状态，为您提供当前所在省份的高考资讯等服务，全国高校在当前省份的招生录取信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">为了提升定位服务准确性和成功率，为特定连续定位场号提供持续定位服务，并向最终用户提供问题追踪，排查，诊断服务并进行数据统计，以保障产品和服务的正常运行，我们会处理您的设备信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">为帮助开发者向最终用户提供定位功能，无需最终用户手动输入自身地理坐标就可以向开发者应用反馈最终用户的位置信息，我们会收集您的位置信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">为了作为辅助定位依据实现给开发者提供最终用户更精准的位置信息的目的，我们会收集您的连接WiFi信息</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(2)QQSDK(open_sdk)收集您的网络状态信息，以根据您的指令实现账号的登录、以及资讯信息的分享;深圳市腾讯计算机系统有限公司https://privacy.qq.com/</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(3)微信SDK(tencent.wechat)收集您的网络状态信息，以根据您的指令实现账号的登录、以及资讯信息的分享、微信支付功能;深圳市腾讯计算机系统有限公司https://open.weixin.qq.com/</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(4)支付宝支付(alipay)SDK：使用您的网络权限以实现支付功能;支付宝中国网络技术有限公司https://render.alipay.com/p/c/k2cx0tg8</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(5)移动安全联盟设备标示SDK：获取移动智能终端补充设备标识体;中国信息通信研究院官网http://www.msa-alliance.cn/</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(6)极光推送(jPush)收集您的设备信息、网络信息、应用信息以及设备参数以及系统信息，为您提供精准的推送服务以及政策提醒深圳市和讯华谷信息技术有限公司https://www.jiguang.cn/license/privacy</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(7)华为推送SDK收集您的设备信息、运营商信息及网络状态用于华为设备的用户进行消息推送;华为技术有限公司https://consumer.huawei.com/cn/privacy/privacy-policy/</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(8)小米推送SDK收集您的设备信息、运营商信息及网络状态用于小米设备的用户进行消息推送;小米科技有限公司https://dev.mi.com/console/doc/detail?pId=1822</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(9)OPPO推送SDK收集您的设备信息、运营商信息及网络状态用于OPPO设备的用户进行消息推送;OPPO广东移动通信有限公司https://www.oppo.com/cn/service/help/640?id=640&amp;name=服务政策&amp;hdscreen=1</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(10)VIVO推送SDK收集您的设备信息、运营商信息及网络状态用于VIVO设备的用户进行消息推送;维沃移动通信有限公司https://www.vivo.com.cn/about-vivo/privacy-policy</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(11)GrowingIO：使用GrowingIO平台SDK统计App内部页面访问数据，如用户登录统计，页面访问率，按钮点击率等;北京易数科技有限公司https://www.growingio.com/</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(12)高德地图SDK：使用高德地图SDK收集您的位置信息，为您提供当前所在省份的高考资讯等服务;高德软件有限公司https://lbs.amap.com/pages/privacy/</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（13）极光安全认证 SDK</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">服务类型：安全认证服务</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">SDK 处理个人信息类型：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">设备信息（IMEI/MAC/AndroidID/IDFA/OAID/OpenUDID/GUID/SIM</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">卡 IMSI
                信息等）：用于检测和防止欺诈活动，检验用户行为是否为机器程序批量自动化操作判断提供相关依据，信息脱敏处理后用来建立异常流量识别模型，实现流量反欺诈技术。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">网络信息与位置信息（IP 地址，WiFi 信息，基站信息等相关信息）：优化 SDK
                与极光服务器的网络连接请求，保证服务的稳定性和连续性；信息脱敏处理后用来建立异常、虚拟设备识别模型，实现流量反欺诈技术。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户生物轨迹信息：用户在操作验证过程中所产生的滑动、点击、鼠标移动的轨迹等信息；这些信息脱敏处理后用来建立模拟、暴力、伪造轨迹识别模型，实现流量反欺诈技术。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">APP 安装列表信息：我们为向您提供智能认证服务，需要通过该信息识别用户应用环境，帮助你的网站与 APP 识别与拦截机器程序的批量自动化操作。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（14）腾讯浏览服务SDK(以下简称“TBS SDK“或“SDK产品”)</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">为实现SDK产品的网页等浏览功能所必须，我们将向终端用户或开发者收集终端用户在使用与SDK产品相关的功能时产生的如下个人信息</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">①通过去标识化、加密传输和处理的安全处理方式收集设备信息（设备型号、操作系统、CPU类型）应用于浏览器内核升级和禁用细化控制；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">②通过去标识化、加密传输和处理的安全处理方式收集应用信息（宿主应用包名，版本号）应用于浏览器内核升级及禁用细化控制需要；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">③通过去标识化、加密传输和处理的安全处理方式收集Wi-Fi状态和参数应用于判断Wi-Fi是否连接，进行网络优化；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">④内存中获取使用，程序退出立即销毁：在用户主动打开网页且网页自身需要定位位置时触发收集位置信息、附近的Wi-Fi、CellID。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">为实现SDK产品的相应功能所必须,我们会通过【美术教学质量检测考务系统APP】在对应的功能场景下申请所需权限。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">安卓操作系统：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">通过SDK下载内核需要、以及用户打开网页体验网络加载优化需要，获取网络权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">内核运行必要异常日志需要读写文件获取存储权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">用户主动操作网页或文件内容复制粘贴，需要访问剪切板权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">用户主动打开网页，且网页自身需要定位位置获取地理位置权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">用户主动打开网页，支持网页中的获取传感器数据，支持网页传感器功能获取传感器权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">用户主动打开网页需要摄像头功能时，支持网页中的图像捕捉功能获取摄像头权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">在内核加载失败的情况下打开文件，或者打开TBS不支持格式的文件，会弹框给用户选择使用其他应用打开该文件获取已安装的支持文件打开的应用列表；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">用户主动打开网页需要录音功能时，支持网页中录音功能获取麦克风权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（15）Bugly SDK(以下简称“SDK产品”)由深圳市腾讯计算机系统有限公司 (以下简称“我们”)开发</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">①为实现SDK产品功能所需收集的个人信息：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">Bugly SDK 安卓版</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">通过服务器处理，为排查崩溃问题，帮助美术教学质量检测考务系统APP提升稳定性，需要收集个人信息类型：手机型号、手机品牌、Android系统版本、Android系统api等级、厂商系统版本、cpu架构类型、设备是否root、磁盘空间占用大小、sdcard空间占用大小、内存空间占用大小、网络类型；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">通过仅读取，不保存到本地，也不上传服务器的方式判断当前进程的ANR状态，排查崩溃问题,
                帮助美术教学质量检测考务系统APP提升稳定性，需要收集个人信息类型：应用当前正在运行的进程名和PID；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">Bugly SDK iOS版</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">通过服务器处理，为排查崩溃问题，帮助美术教学质量检测考务系统APP提升稳定性，需要收集个人信息类型：设备型号、操作系统版本、操作系统内部版本号、wifi状态、cpu属性、内存剩余空间、磁盘空间/磁盘剩余空间、运行时手机状态(进程所占内存、虚拟内存等)、idfv、是否越狱、地区编码。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">②为实现SDK产品的相应功能所必须,我们会通过【美术教学质量检测考务系统APP】在对应的功能场景下申请所需权限。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">为实现查看网络状态，用于数据上报，实现开发者查看崩溃信息的目的，安卓需要获取android.permission.INTERNET、android.permission.ACCESS_NETWORK_STAT的权限；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">iOS需要获取网络权限。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（16）友盟统计SDK</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">我们通过采集唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡 IMSI
                信息）对用户进行唯一标识，以便进行诸如用户新增等数据统计。在特殊情况下（如用户使用平板设备或电视盒子时），无法通过唯一设备识别码标识设备，我们会将设备Mac地址作为用户的唯一标识，以便正常提供统计分析服务。统计分析服务具备基础的反作弊功能，通过获取用户位置信息及IP地址，剔除作弊设备，同时矫正用户的地域分布数据，提高报表数据的准确性。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（17）一键登录相关sdk</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">移动SDK</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">中移互联网有限公司</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">一键登录：访问网络、网络信息状态、WIFI状态、修改WIFI状态、允许改变网络连接状态、读取手机状态</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">https://wap.cmpassport.com/resources/html/contracts.html</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">联通SDK</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">中国联合网络通信有限公司</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">一键登录：访问网络、网络信息状态、WIFI状态、修改WIFI状态、允许改变网络连接状态、读取手机状态</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">https://opencloud.wostore.cn/authz/resource/html/disclaimer.html</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">电信SDK</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">世纪龙信息网络有限责任公司</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">一键登录：访问网络、网络信息状态、WIFI状态、修改WIFI状态、允许改变网络连接状态、读取手机状态</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">https://e.189.cn/sdk/agreement/detail.do</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">三、我们如何共享、转让、公开披露您的个人信息</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、共享</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(1)我们不会与本公司以外的任何公司、组织和个人共享您的个人信息，除非获得您的明确同意。我们仅会处于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途，对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息，并且我们会要求合作伙伴提供数据安全能力和信息安全资质(如等级保护测评、信息安全管理体系等)说明。在个人敏感数据使用上，我们要求第三方采用数据脱敏加密技术，从而更好地保护用户数据。如果您对第三方使用您的个人信息有异议或发现这些第三方开发的应用程序存在风险时，我们建议您终止相关操作以保护您的合法权益，您还可以与相应的第三方协商解决，我们将尽可能提供帮助。如果我们在此过程中违反法律法规的规定，给您造成损失的，我们将承担因此引起的相应责任。目前，我们会在以下情形中，向您征求您对共享个人信息的授权同意：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">1)我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">2)我们可能会向合作伙伴及第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">a、第三方商家：我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">b、特定服务合作方：为了让您完成交易、实现交易目的，我们需要向您的交易相对方提供交易号与相关支付信息，同时您的交易相对方可在付款过程中或付款成功后查看您的部分注册信息;某些产品或服务可能由第三方提供或由我们与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">c、纠纷处理：当您在使用App及相关服务的过程中，如与其他用户、商家等第三方发生纠纷、投诉或被投诉时，为了保护您与其他主体的合法权益，维护良好的交易秩序和社会环境，我们会基于有关部门的要求将您的姓名、有效证件号码、联系方式、纠纷内容提供给消费者权益保护部门及监管机关，以便及时处理纠纷，但法律法规明确禁止提供的除外。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(2)我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。如果我们在此过程中违反法律法规的规定，给您造成损失的，我们将承担因此引起的相应责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、转让</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(1)在获得明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息;</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供;</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(3)在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">3、公开披露</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">公开披露是指向社会或不特定人群发布信息的行为。原则上我们不会对您的个人信息进行公开披露，除非取得您的明确同意后，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">4、共享、转让、公开披露个人信息授权同意的例外情形</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">艺联时代一定不会公开、编辑或透露用户的注册资料及保存在艺联时代各项服务中的非公开内容，除非艺联时代在诚信的基础上认为透露这些信息在以下几种情况是必要的：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">(1)遵守有关法律规定，包括在国家有关机关查询时，提供用户在美术教学质量检测考务系统的网页上发布的信息内容及其发布时间、互联网地址或者域名以及其他用户上传至美术教学质量检测考务系统的信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(2)遵从美术教学质量检测考务系统产品服务程序。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(3)保持维护艺联时代的商标所有权。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(4)在紧急情况下维护用户个人和社会大众的隐私安全。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(5)艺联时代认为必要的其他情况下。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">用户在此授权艺联时代可以向其电子邮箱发送商业信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">四、我们如何存储和保护您的个人信息</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、存储</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">艺联时代不对用户所发布信息的删除或储存失败负责。艺联时代保留判定用户的行为是否符合美术教学质量检测考务系统服务条款的要求和精神的权利，如果用户违背了服务条款的规定，则中断或删除其帐号。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、保护</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">在您同意美术教学质量检测考务系统隐私政策后，我们将进行集成SDK的初始化工作，会收集您的设备基础信息，以保障服务正常数据统计及安全风控，我们会努力采取安全技术保护您的个人信息，未经您同意，我们不会从第三方获取、共享或对外提供您的信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">3、账户封禁</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">为了保护广大用户的使用权益，艺联时代有权对恶意调用学校及专业接口数据（包括但不限于学校列表、学校专业、专业列表、招生信息、招生分数等）、非正常调用数据或可能对产品稳定性造成影响的调用数据行为的账户进行封禁，并依法向相关部门举报恶意爬取数据行为，维护自身的合法权益。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">五、我们如何管理您的个人信息</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、访问及修改</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">您有权访问及修改您的个人信息，法律法规规定的例外情况除外。您可以通过登录App账户，并通过如下渠道访问及修改您的个人信息：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(1)在“我的→编辑”中访问及修改账号及学生信息，包括头像、昵称、账号位置、学校、年级及成绩信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(2)在“我的→关注院校”中访问及修改关注的院校信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(3)在“我的→关注专业”中访问及修改关注的专业信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(4)在“我的→我的订单”中访问及修改订单信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(5)在“我的→我的收藏”中访问及修改收藏的资讯和视频信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(6)在“我的→我的志愿表”中访问及修改志愿表信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(7)在“我的→测评报告”中访问及修改测评报告信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">如过您无法通过上述链接访问这些个人信息，您可以通过在“我的→意见反馈”中联系我们，收到您的意见或反馈后，我们会尽快向您提供支持。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、结束服务</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户或艺联时代可随时根据实际情况中断服务。艺联时代不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对艺联时代服务不满，用户享有以下的追索权：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(1)不再使用美术教学质量检测考务系统的服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(2)结束用户使用美术教学质量检测考务系统服务的资格。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(3)书面通告艺联时代停止该用户的服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">结束用户服务后，用户使用美术教学质量检测考务系统服务的权利马上中止。从那时起，艺联时代不再对用户承担任何义务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">六、帐号的注销及冻结</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">1、您可以在我们的产品中直接申请注销账户，操作路径为“我的→设置→账号注销”。您注销账户后，我们将停止为您提供产品服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">2、美术教学质量检测考务系统帐号的失效期为3个月。如果一个帐号连续3个月不曾使用，即没有以Web方式登录，届时其原有信息将全部丢失。用户已经获悉，为了保证登录的顺利及有效，3个月内至少要使用一次。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">七、我们如何处理未成年人个人信息的保护</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">1、我们的产品、网站和服务主要面向年满16周岁的用户。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。对于经父母同意而收集未成年人(特别是未满十四周岁的儿童)个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">八、服务条款的修改</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">艺联时代会在必要时修改服务条款，一旦本政策的内容发生变动，艺联时代将会直接在美术教学质量检测考务系统网站上公布修改之后的政策内容，该公布行为视为艺联时代已经通知用户修改内容。艺联时代也可通过其他适当方式向用户提示修改内容。如果不同意艺联时代对本政策相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受艺联时代对本政策相关条款所做的修改。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">九、服务修订</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">艺联时代特别提示用户，艺联时代为了保障公司业务发展和调整的自主权，拥有随时修改或中断服务而不需通知用户的权利，艺联时代行使修改或中断服务的权利不需对用户或任何第三方负责。用户必须在同意本条款的前提下，艺联时代才开始对用户提供服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>

        </p>
    </div>
</template>