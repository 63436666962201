
import axios from '../utils/axios'

export function configList() {//考点详情
    return axios.get(`/config`)
}


// export function delClbum(id) {
//     return axios.delete(`/clbum/${id}`);
// }