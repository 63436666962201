
import axios from '../utils/axios'

export function getGoodsList(query)
{//获取历年优秀试卷.....
    return axios.get(`/marking/exam_photo_back/get_goods_list`,{params:query})
}

export function examPhotoInfo(query)
{//学生考试试卷详情
    return axios.get(`/marking/exam_photo/show`,{params:query})
}

export function addExamPhoto(params)
{//上传考试试卷
    return axios.post(`/marking/exam_photo`,params)
}

export function updateExamPhoto(params)
{//更新学生试卷
    return axios.put(`/marking/exam_photo`,params)
}


export function login(params)
{
    return axios.post(`/marking/teacher/login`,params)
}

// export function delClbum(id) {
//     return axios.delete(`/clbum/${id}`);
// }