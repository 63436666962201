<template>
<s-header :name='"-照片审核"'></s-header>
<!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
      <div v-if="state.user.identity=='school'">
        <van-field
          v-model="state.value"
          readonly
          clickable
          name="picker"
          label="选择班级"
          placeholder="点击选择班级 "
          @click="state.showPicker = true"
        />
        <van-popup v-model:show="state.showPicker" position="bottom">
          <van-picker
            :columns="state.columns"
            @confirm="onConfirm"
            @cancel="state.showPicker = false"
          />
        </van-popup>
      </div>

    <div style="width:100%;height:auto;background:#F6F6F6;">
      <div v-if="state.user.identity=='school'">
          <van-notice-bar wrapable  left-icon="info-o">
              考点主考可通过本程序审核考生所提交的照片,如不合规点击照片可重新拍摄;<br/>
              照片要求:直接拍摄考生本人正面,要求免冠,光线良好
          </van-notice-bar>
          <!-- <div style="margin: 10px;">
              <van-button block type="primary" >查询</van-button>
          </div> -->
          <div class="van-grid">
              <div  v-for="(item, index) in state.sList.data" :key="index" class="van-grid-item" style="margin-left:3%;margin-right:3%;margin-bottom:3%;float:left;width:44%;height:auto;">
                  <div @click="editUrl(item.id,item.name,item.avatar)"  class="van-grid-item__content van-grid-item__content--center">
                      <div class="van-image">
                          <img :src="prefix(item.avatar)" class="van-image__img" style="object-fit: contain;">
                          <span>{{item.name}}</span>
                      </div>
                  </div>
              </div>
              
          </div>
          <van-pagination v-model="currentPage" :page-count="state.sList.last_page" mode="simple" @change="pageC" />
      </div>

      <div v-if="state.user.identity=='clbum'">
          <br/>
          <div class="van-grid">
              <div  v-for="(item, index) in state.sList.data" :key="index" class="van-grid-item" style="margin-left:3%;margin-right:3%;margin-bottom:3%;float:left;width:44%;height:auto;">
                  <div @click="editUrl(item.id,item.name,item.avatar)"  class="van-grid-item__content van-grid-item__content--center">
                      <div class="van-image">
                          <img :src="prefix(item.avatar)" class="van-image__img" style="object-fit: contain;">
                          <span>{{item.name}}</span>
                      </div>
                  </div>
              </div>
              
          </div>
          <van-pagination v-model="currentPage" :page-count="state.sList.last_page" mode="simple" @change="pageC" />
      </div>
<!-- <van-pagination v-model="currentPage" :total-items="24" :items-per-page="5" /> -->

<!-- <div  v-for="(item, index) in state.sList" :key="index" class="van-grid-item" style="flex-basis: 48%;margin-right:4%;margin-bottom:3%;">
        <div class="van-grid-item__content van-grid-item__content--center">
            <div class="van-image">
                <img :src="getImgUrl(item.avatar)" class="van-image__img" style="object-fit: contain;">
                <span>{{item.name}}</span>
            </div>
        </div>
     </div> -->
<br/>
<div style="margin: 10px;">
        <router-link :to="{path:'/dashboard'}">
            <van-button plain block type="primary" >
                <span style="color:#555555;">返回主页</span>
            </van-button>
        </router-link>
</div>
 <div class="bottom-bg"></div>
</div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { onMounted, reactive,ref } from 'vue';
import sHeader from '@/components/Header'
import {clbumList} from '@/apis/clbum'
import {studentList} from '@/apis/student'
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import { prefix,getLocalUser} from '@/utils/function'
export default {
   components: {
    sHeader
  },
  setup() {
      const router = useRouter();
      const currentPage = ref(2);
      const state = reactive({
      user:getLocalUser('user'),
      identity:getLocalUser('user').identity,
      value: '',
      showPicker: false,
      clbumList:{},
      sList:{},
      columns:[
                {
                  id: 0,
                  text: '全部班级',
                },
              ]
    });
    onMounted(async()=>{
        Toast.loading({
            message: '请稍等...',
            forbidClick: true,
            duration:0
        });
        let list = await clbumList({search:1});

        if(state.identity=='school'){
          state.sList = await studentList({school_id:getLocalUser('user').id});
        }else if(state.identity=='clbum'){
          state.sList = await studentList({clbum_id:getLocalUser('user').id});
        }
        console.log('clbum',list);
        console.log('student',state.sList);
        for(let i = 0; i < list.length; i++){
            state.columns.push(list[i])
        }
        state.clbumList = list.keys('name')
        Toast.clear();
        console.log(clbumList);
    })

    const onConfirm = async(value,index) => {//选择班级
        if(value.id>0){
          state.sList = await studentList({school_id:getLocalUser('user').id,clbum_id:value.id});
        }else{
          state.sList = await studentList({school_id:getLocalUser('user').id});
        }
        state.value = value.text;
        state.showPicker = false;
    };
    const editUrl = (id,name,avatar)=>{
        router.push({path:'/edit-photo',query:{id:id,name:name,avatar:avatar}})
    }

    const pageC = async(value)=>{//分页查询
      Toast.loading({
            message: '请稍等...',
            forbidClick: true,
            duration:0
        });
      currentPage.value=value;
      state.sList = await studentList({school_id:getLocalUser('user').id,page:value});
      Toast.clear();
    }
    return {
      state,
      onConfirm,
      editUrl,
      pageC,
      currentPage,
      prefix
    };
  },
};
</script>
<style  scoped>
.font-color{
    color:#555555;
}
.van-row{
    border-bottom:1px solid #EBEBEB;
}
.van-col{
    height:4em;line-height:4em;
}
.bottom-bg {
  height: 0.9rem;
  width: 100%;
  /* background-color: #F6F6F6; */
  border: none;
}
.demo-preview{
    background: #ffff;
    margin:10px;
    height:9em;
}
p{
    color:#747474
}

.van-pagination-item-width{
  width:10px;
}

.clbum-info{
    float:left;margin-left:5%;margin-top:7px;font-size:13px;width:80%;
}
h1{
    float:left;margin-top:25px;
}
.clbum-share{
float:right;margin-right:3%;
}

</style>