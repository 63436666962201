<template>
    <div></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { reactive ,onMounted,ref} from 'vue';
import { useRouter,useRoute } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const fileList = ref([]);
    const state = reactive({
      });
    onMounted(async()=>{
        console.log(route.query);
        router.push({path:route.query.url,query:route.query});
    })
    return {
    };
  },
};
</script>
