
import axios from '../utils/axios'

export function examList(query) {//考点详情
    return axios.get(`/exam`,{params:query})
}

export function getUploadExamList() {//考点详情
    return axios.get(`/exam/get_upload_exam_list`)
}
export function addClbum(params)
{
    return axios.post(`/clbum`,params)
}
export function delClbum(params)
{
    console.log(params);
    return axios.delete(`/clbum`,{data:params})
}
export function getExamTime()
{//获取公共考点考试时间.....
    return axios.get(`/exam/get_exam_time`)
}



// export function delClbum(id) {
//     return axios.delete(`/clbum/${id}`);
// }