<template>
<s-header :name='"-老师登录"'></s-header>
<!-- 主考登录 -->
<van-form @submit="onLogin">
  <van-cell-group inset>
    <van-field
      v-model="state.user.name"
      name="name"
      label="用户名"
      placeholder="请输入用户名"
      :rules="[{ required: true, message: '请输入用户名' }]"
    />
    <van-field
      v-model="state.user.password"
      type="password"
      name="password"
      label="密码"
      placeholder="请输入密码"
      :rules="[{ required: true, message: '请输入密码' }]"
    />
  </van-cell-group>
  <div style="margin: 10px;">
    <van-button plain   block type="primary" native-type="submit">
      <span style="color:#555555;">点击登录</span>
    </van-button>
  </div>
</van-form>
<!-- 
<van-button @click="testUser" plain style="width:48%;margin-left:4%"  type="primary" size="normal" >
      <span style="color:#555555;">点击测试</span>
    </van-button> -->
</template>
<script>
import {reactive,ref,onMounted } from 'vue';
import { useRouter ,useRoute } from "vue-router";
import { login } from '@/apis/marking';
// import { Toast } from 'vant';
import sHeader from '@/components/Header';
import { setLocal} from '@/utils/function'
import axios from 'axios'
import { getLocalUser} from '@/utils/function'
export default {
  components:{
    sHeader
  },
  setup() {
    const router = useRouter(); // 第一步
    const route = useRoute(); // 第一步
    const active = ref(0);
    const state = reactive({
      show:false,
      identity:route.params.id,
      reg0:0,
      reg1:1,
      code: '',
      password: '',
      list:{},
      user:getLocalUser('marking_user')!==null?getLocalUser('marking_user'):{'name':'','password':''}
    });
    onMounted(async()=>{//判断是否登录过,如果有marking_token直接跳转到优秀试卷页面.
      // let token = localStorage.getItem('marking_token');
      // if (token !== null && token !== undefined && token !== '') {
      //     router.push({path:"good-paper"});
      // }
    })
    const onLogin =  async (values) => {//主考  班主任登录用同一个函数....
      axios.defaults.baseURL = 'https://bm.yszytb.com'//请求阅卷系统地址不一样;需要重新进行赋值
      state.user = await login(values);
      setLocal('marking_user', JSON.stringify(state.user));
      setLocal('marking_token', state.user.authorization);
      axios.defaults.baseURL = `https://${process.env.VUE_APP_API_HTTP}/api/v1`//请求阅卷系统地址不一样;需要重新进行赋值
      axios.defaults.headers['Authorization'] = localStorage.getItem('marking_token')
      router.push({ path: '/good-paper'});
      // window.location.href = '/#/perfect-info'
      // 需要刷新页面，否则 axios.js 文件里的 token 不会被重置
    };
    return {
      state,
      onLogin,
      active,
    };
  },
};
</script>
<style  scoped>
.clbum-info {
  float: left;
  margin-left: 5%;
  font-size: 13px;
  width: 80%;
}
.demo-preview {
  margin:10px;
  border:1px solid #1989fa;
  background: #ffff;
  height: 6em;
}
.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>