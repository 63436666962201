<template>
    <s-header :name='"-意见反馈"'></s-header>
    <van-notice-bar wrapable left-icon="info-o">
        如有报名问题请联系我们:4000829858
    </van-notice-bar>
    <div style="background:#F6F6F6;width:100%;height:100%;">
        <van-form @submit="onSubmit" :submit-on-enter="false">
            <van-field name="type" label="类型">
                <template #input>
                    <van-radio-group v-model="state.info.checked" direction="horizontal">
                        <van-radio name="0">报名问题</van-radio>
                        <van-radio name="1">缴费问题</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field v-model="state.info.describe" name="describe" label="问题描述" type="textarea" placeholder="请填写问题描述"
                :rules="[{ required: true, message: '请填写问题描述' }]" />

            <van-field v-model="state.info.student_id" label="考生id" name="student_id" v-show="false" />

            <div style="margin: 20px 10px;">
                <van-button @click="back" style="width:48%;" plain type="primary" size="normal">
                    <span style="color:#555555;">返回主页</span>
                </van-button>
                <van-button style="width:48%;margin-left:4%" type="primary" size="normal" native-type="submit">
                    <span>提交</span>
                </van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import sHeader from '@/components/Header';
import { addFeedback } from '@/apis/feedback';
import { Toast } from 'vant';
import { getLocalUser } from '@/utils/function'
export default {
    components: {
        sHeader
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            info: {
                describe: '',
                checked: '0',
                student_id: getLocalUser('user').id
            }
        });

        const onSubmit = async (values) => {
            Toast.loading({
                message: '上传中...',
                forbidClick: true,
                duration: 0
            });
            await addFeedback(values);
            Toast('反馈成功');
            router.go(0);
        };

        const back = () => {
            router.go(-1);
        }
        return {
            state,
            onSubmit,
            back
        };
    },
};
</script>
<style  scoped>
.clbum-info {
    float: left;
    margin-left: 5%;
    font-size: 13px;
    width: 80%;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 10em;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}
</style>