import wx from "weixin-js-sdk";
/*
微信支付方法
获取微信加签信息
@param{data}:获取的微信加签
@param{cb}:成功回调
@param{errorCb}:失败回调
@param{share}:判断是否是调用分享的微信接口
*/
let wechatInterface = (data, cb, errorCb, extra) => {
    let [appId, timestamp, nonceStr, signature, packages, signType, paySign, debug, jsApiList] = [data.appId, data.timestamp, data.nonceStr, data.signature, data.package, data.signType, data.paySign, data.debug, data.jsApiList];
    wx.config({
        debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: jsApiList// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
    // wx.ready(function(){//验证接口调用是否成功
    //     wx.checkJsApi({
    //     jsApiList: ['updateAppMessageShareData','updateTimelineShareData','chooseWXPay','scanQRCode',], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
    //         success: function(res) {
    //             console.log(res);
    //         // 以键值对的形式返回，可用的 api 值true，不可用为false
    //         // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
    //         },
    //         fail:function(res){
    //             let message = 'checkJsApi fail=' + JSON.stringify(res);
    //             console.log(message);
    //         }
    //     });
    // });
    if (!extra) {
        wx.ready(function () {//微信支付
            wx.chooseWXPay({
                timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
                package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: paySign, // 支付签名
                success: function (res) {// 支付成功后的回调函数
                    console.log('支付成功', res);
                    cb(res);
                },
                fail: function (res) {
                    console.log('支付失败', res);
                    errorCb(res)
                }
            });
        });
    } else {
        switch (extra.type) {
            case 'share'://微信分享处理
                wx.ready(function () {//微信分析
                    wx.updateAppMessageShareData({
                        title: extra.title, // 分享标题
                        desc: extra.desc, // 分享描述
                        link: extra.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: extra.imgUrl, // 分享图标
                        success: function (res) { // 分享成功
                            // alert('调用成功');
                            // alert(JSON.stringify(res));
                            // alert(JSON.stringify(jsApiList));
                            console.log('分享成功', res);
                            cb(res);
                        },
                        fail: function (res) {
                            // alert('调用失败');
                            // alert(JSON.stringify(res));
                            // alert(JSON.stringify(jsApiList));
                            console.log('分享失败', res);
                            errorCb(res)
                        }
                    })
                })
                break;
            case 'qrcode'://微信扫描处理
                wx.ready(function () {
                    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                    wx.scanQRCode({
                        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                        scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
                        success: function (res) {
                            console.log('扫描成功', res);
                            cb(res);
                        },
                        fail: function (res) {
                            console.log('扫描失败', res);
                            errorCb(res)
                        }
                    });
                });
                break;
        }
    }
    wx.error(function (res) {
        console.log('error', res);
    });
}
export default wechatInterface;