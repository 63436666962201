<template>
    <title>用户服务协议</title>
    <h2 style="margin:auto;text-align: center;">用户服务协议</h2>
    <div style="padding-left: 5%;padding-right: 5%;">
        <p>
            <span style="text-wrap: nowrap;">更新时间：2023年7月14日</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">生效时间：2022年7月14日</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">美术教学质量检测考务系统用户协议</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">一、服务条款的确认和接纳</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">美术教学质量检测考务系统（bmweb.yszytb.com）及其涉及到的产品、相关软件的所有权和运作权归艺联时代教育科技有限公司（以下简称艺联时代）所有，艺联时代享有对美术教学质量检测考务系统上一切活动的监督、提示、检查、纠正及处罚等权利。用户在注册程序过程中点击&#39;同意&#39;按钮完成注册，即表示用户与艺联时代已达成协议，自愿接受本服务条款的所有内容。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">二、服务保护条款</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、艺联时代运用自己的操作系统通过国际互联网络为用户提供各项服务，用户必须：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（1）提供设备，包括个人电脑一台、调制解调器一个及配备上网装置。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（2）个人上网和支付与此服务有关的电话费用。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、考虑到艺联时代产品服务的重要性，用户同意：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（1）提供及时、详尽及准确的个人资料。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（2）不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（3）用户允许本平台提供第三方服务，且向第三方平台直接跳转。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">3、用户可授权艺联时代向第三方透露其注册资料，否则艺联时代不能公开用户的姓名、住址、出生地址、电子邮箱、帐号。除非：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（1）事先获得用户明确授权后，用户要求艺联时代或授权某人通过电子邮件服务或其他方式透露这些信息。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（2）相应的法律、法规要求以及按照有关政府主管部门的要求，需要艺联时代提供用户的个人资料。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（3）为了维护公众以及美术教学质量检测考务系统合法利益。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">（4）美术教学质量检测考务系统可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与美术教学质量检测考务系统同等的保护用户隐私的责任，则美术教学质量检测考务系统有权将用户的注册资料等提供给该第三方。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（5）在不透露单个用户隐私资料的前提下，美术教学质量检测考务系统有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">4、如果用户提供的资料不准确，不真实，不合法有效，艺联时代保留结束用户使用艺联时代各项服务的权利。用户在享用艺联时代各项服务的同时，同意接受艺联时代提供的各类信息服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">5、艺联时代定义的信息内容包括：文字、软件、声音、相片、录像、图表、在广告中全部内容、艺联时代为用户提供的商业信息等，所有这些内容受版权、商标权、和其它知识产权及所有权法律的保护。所以，用户只能在艺联时代授权下才能使用这些内容，而不能擅自复制、修改、编撰这些内容、或创造与内容有关的衍生产品。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">6、如果用户未遵守本服务条款的任何一项，艺联时代有权利即终止提供一切服务，并保留通过法律手段追究责任的权利。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">7、使用美术教学质量检测考务系统提供的服务由用户自己承担风险，在适用法律允许的最大范围内，艺联时代在任何情况下不就因使用或不能使用美术教学质量检测考务系统提供的服务所发生的特殊的、意外的、直接或间接的损失承担赔偿责任。即使已事先被告知该损害发生的可能性。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">8、您同意，美术教学质量检测考务系统有通过邮件、短信、电话、APP通知或声明等形式，向您及您指定的收货人发送订单信息、促销活动、售后服务、客户服务等告知信息的权利。如果您不希望接收上述信息，可退订。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">9、用户须明白，使用美术教学质量检测考务系统提供的服务涉及到Internet服务和电信增值服务，可能会受到各个环节不稳定因素的影响。因此服务存在因不可抗力、计算机病毒或黑客攻击、国家相关行业主管部门及电信运营商的调整、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。用户须承担以上风险，艺联时代不作担保。对因此导致用户不能发送、上传和接受阅读消息、或接发错消息，或无法实现其他通讯条件，艺联时代不承担任何责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">10、用户须明白，在使用美术教学质量检测考务系统提供的服务存在有来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）的匿名或冒名的信息的风险，用户须承担以上风险，艺联时代和合作公司对服务不作任何类型的担保，不论是明确的或隐含的，包括所有有关信息真实性、适用性、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，不承担任何责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">三、用户账号注册及使用规则</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、用户的帐号、密码和安全性</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户一旦成功注册，将得到一个密码和帐号。如果用户未保管好自己的帐号和密码而对其自身、艺联时代或第三方造成的损害，用户将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。用户可随时改变自己的密码和图标，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，立即通告艺联时代。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、用户账号使用规则</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">特别提示用户，使用互联网必须遵守国家有关的政策和法律，包括刑法、国家安全法、保密法、计算机信息系统安全保护条例等，保护国家利益，保护国家安全，对于违法使用互联网络而引起的一切责任，由用户负全部责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（1）用户在申请使用美术教学质量检测考务系统提供的网络服务时，必须向艺联时代提供准确的个人资料，如个人资料有任何变动，必须及时更新。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">（2）用户注册成功后，艺联时代将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（3）用户不得使用美术教学质量检测考务系统服务发送或传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1）反对宪法所确定的基本原则的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">3)损害国家荣誉和利益的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">4)煽动民族仇恨、民族歧视，破坏民族团结的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">5)破坏国家宗教政策，宣扬邪教和封建迷信的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">6)散布谣言，扰乱社会秩序，破坏社会稳定的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">8)侮辱或者诽谤他人，侵害他人合法权益的；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">9)含有法律、行政法规禁止的其他内容的。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（4）用户在使用美术教学质量检测考务系统网络服务过程中，必须遵循以下原则：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1)遵守中国有关的法律和法规；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2)不得为任何非法目的而使用网络服务系统；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">3)遵守所有与网络服务有关的网络协议、规定和程序；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">4)不得利用美术教学质量检测考务系统网络服务系统进行任何可能对互联网的正常运转造成不利影响的行为；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">5)不得利用美术教学质量检测考务系统络服务系统传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">6)不得利用美术教学质量检测考务系统络服务系统进行任何不利于艺联时代的行为。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（5）使用美术教学质量检测考务系统网站服务，用户应加强个人资料的保护意识，并注意个人密码的密码保护。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">（6）盗取他人用户帐号或利用网络通讯骚扰他人，均属于非法行为。用户不得采用测试、欺骗等任何非法手段，盗取其他用户的帐号和对他人进行骚扰。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">四、免责及赔偿声明</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、用户理解并同意：模拟报志愿为信息技术类服务，一经激活，即视为使用。已使用情况下，以下情形不支持退款：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(1)用户因自身原因导致会员信息错误的、误买的;</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(2)自支付时间起，使用且超过12小时的;</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(3)使用数据且已生成志愿表的;</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(4) 成绩修改次数(2次)已有消耗的;</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(5)非产品或服务原因申请退款的;</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">(6)其它用户因自身原因申请退款的。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">2、艺联时代对直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用产品服务，在网上购买商品或类似服务，在网上进行交易，非法使用服务或用户传送的信息有所变动。用户的上述行为引起对艺联时代或第三方的损害，应当依法承担责任并向艺联时代进行赔偿，艺联时代可以自行或协助第三方使用用户提供的任何信息进行维权。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">3、未经艺联时代同意禁止进行商业性行为</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户承诺不经艺联时代书面同意，不能利用艺联时代各项服务在中国教育在线或相关网站上进行销售或其他商业性行为。用户违反此约定，艺联时代将依法追究其违约责任，由此给艺联时代造成损失的，艺联时代有权进行追偿。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">4、拒绝提供担保</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">艺联时代不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性、安全性、出错发生都不作担保。艺联时代拒绝提供任何担保，包括信息能否准确、及时、顺利地传送。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户理解并接受下载或通过艺联时代产品服务取得的任何信息资料取决于用户自己，并由其承担系统受损或资料丢失的所有风险和责任。艺联时代对在服务网上得到的任何商品购物服务或交易进程，都不作担保。用户不会从艺联时代收到口头或书面的意见或信息，艺联时代也不会在这里作明确担保。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">5、参与广告策划</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">在艺联时代书面许可下用户可在他们发表的信息中加入宣传资料或参与广告策划，在美术教学质量检测考务系统各项免费服务上展示他们的产品。任何这类促销方法，包括运输货物、付款、服务、商业条件、担保及与广告有关的描述都只是在相应的用户和广告销售商之间发生。艺联时代不承担任何责任，艺联时代没有义务为这类广告销售负任何一部分的责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">6、言论</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户承诺发表言论要：爱国、守法、自律、真实、文明。不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、危害国家安全的、泄露国家机密的、破坏国家宗教政策和民族团结的以及其它违反法律法规及政策的内容。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">若用户的行为不符合以上提到的服务条款，艺联时代将作出独立判断立即取消用户服务帐号。用户需对自己在网上的行为承担法律责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">7、内容的所有权</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">内容的定义包括：文字、软件、声音、相片、录象、图表；在广告中的全部内容；电子邮件的全部内容；美术教学质量检测考务系统为用户提供的商业信息。所有这些内容均受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在艺联时代和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">8、若艺联时代已经明示美术教学质量检测考务系统服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">9、用户明确同意其使用美术教学质量检测考务系统的服务所存在的风险将完全由其自己承担；因其使用美术教学质量检测考务系统的服务而产生的一切后果也由其自己承担，艺联时代对用户不承担任何责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">10、艺联时代不担保美术教学质量检测考务系统的服务一定能满足用户的要求，也不担保其服务不会中断，对其服务的及时性、安全性、准确性也都不作担保。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">11、用户同意保障和维护艺联时代及其他用户的利益，由于用户登录网站内容违法、不真实、不正当、侵犯第三方合法权益，或用户违反本政策项下的任何条款而给艺联时代或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">五、知识产权</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户保证和声明对其所提供的作品拥有完整的合法的著作权或完整的合法的授权可以用于其在美术教学质量检测考务系统上从事的活动，保证艺联时代使用该作品不违反国家的法律法规，也不侵犯第三方的合法权益或承担任何义务。用户应对其所提供作品因形式、内容及授权的不完善、不合法所造成的一切后果承担完全责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">用户同意艺联时代对其创作并上传的作品在全世界范围内享有免费的、永久性的、不可撤消的、独家的和完全的许可和再许可权利，以使用、复制、出版、发行、以原有或其他形式进行改编、转载、翻译、传播、表演和展示此等内容（整体或部分）。此许可和再许可权利包括但不限于此作品的著作权、邻接权及获得利益等权利。用户同时承诺，不就上述作品以及上述作品的改编作品对任何第三方进行任何形式的许可使用。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">对于经用户本人创作并上传到美术教学质量检测考务系统的文本、图片、图形、音频和/或视频等资料的商标和/或其它财产权利归属于艺联时代，未经艺联时代同意，用户保证不以任何形式直接或间接发布、播放、出于播放或发布目的而改编或再发行，或者用于其他任何商业目的。同时保证不许可任何第三方从事上述行为。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">艺联时代保留对美术教学质量检测考务系统网站所有内容进行实时监控的权利，并有权依其独立判断对任何违反本政策约定的作品实施删除。艺联时代对于删除用户作品引起的任何后果或导致用户的任何损失不负任何责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">因用户作品的违法或侵害第三人的合法权益而导致艺联时代或其关联公司对第三方承担任何性质的赔偿、补偿或罚款而遭受损失（直接的、间接的、偶然的、惩罚性的和继发的损失），用户对于艺联时代或其关联公司蒙受的上述损失承担全面的赔偿责任。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">六、法律</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">如双方就本政策内容或其执行发生任何争议，双方应尽量友好协商解决。协商不成时，任何一方均可向艺联时代所在地的人民法院提起诉讼。若有任何服务条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">七、青少年用户特别提示</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">1、青少年及使用美术教学质量检测考务系统服务应该在父母和老师的指导下，正确学习使用网络。青少年避免沉迷虚拟的网络世界而影响日常的学习生活。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、青少年用户必须遵守全国青少年网络文明公约：</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">要善于网上学习，不浏览不良信息；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">要诚实友好交流，不侮辱欺诈他人；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">要增强自护意识，不随意约会网友；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">要维护网络安全，不破坏网络秩序；</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">要有益身心健康，不沉溺虚拟时空。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">八、其他</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">1、艺联时代将视向用户所提供服务内容之特性，要求用户在注册艺联时代提供的有关服务时，遵守特定的条件和条款；如该特定条件和条款与以上服务条款有任何不一致之处，则以特定条件和条款为准。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">2、本服务条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，其余条款仍应有效并且有约束力。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">3、本服务条款执行过程中所产生的任何问题，本网站和用户都将友好协商解决。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span
                style="text-wrap: nowrap;">4、本平台的留学咨询服务，分别由启德留学、新东方提供；本平台的国际本科咨询服务，由华夏青藤国际提供；用户同意相关咨询由平台向以上平台跳转，并接受其他平台的服务。</span>
        </p>
        <p>
            <span style="text-wrap: nowrap;"></span>
        </p>
        <p>
            <span style="text-wrap: nowrap;">5、以上条款的解释权归艺联时代最终所有。</span>
        </p>
        <p>

        </p>
    </div>
</template>