//成绩信息
import axios from '../utils/axios'

//成绩列表
export function gradeList(query) {//考点详情
    console.log({params:query});
    return axios.get(`/grade`,{params:query})
}

//成绩分析
export function gradeanAlyseList(query)
{
    return axios.get(`/grade/analyse_list`,{params:query})
}

//考生成绩分析
export function gradeanStudentAlyse(query)
{
    return axios.get(`/grade/student_analyse`,{params:query})
}

//考生成绩曲线图
export function gradeGraph(query)
{
    return axios.get(`/grade/get_grade_graph`,{params:query})
}


export function gradeInfo(query)
{
    return axios.get(`/grade/show`,{params:query})
}
//编辑成绩
export function updateGrade(params)
{
    return axios.put(`/grade`,params)
}
//添加成绩
export function addGrade(params)
{
    return axios.post(`/grade`,params)
}

