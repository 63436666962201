<template>
  <s-header :name='"-上传试卷"'></s-header>
  <!-- <van-cell-group>
    <van-cell title="已设置1个班级" value="添加" />
  </van-cell-group> -->
  <van-cell-group>
    <van-notice-bar v-if="state.page_show == 0" wrapable left-icon="info-o">
      默认扫描学生二维码,上传考生照片;如不能扫描请点击填写按钮,填入考生考号进行试卷上传.
    </van-notice-bar>
    <!--成绩录入显示-->
    <div v-if="state.page_show == 0" class="van-cell">
      <div class="van-cell__title">
        <span>考生考号</span>
      </div>
      <div class="van-cell__value">
        <van-button @click="isShow" plain type="primary" size="small" native-type="submit">
          <span>填写</span>
        </van-button>

        <van-dialog v-model:show="state.addShow" title="考生考号" @confirm="onGetStudent" show-cancel-button>
          <van-field v-model="state.num" label="考生考号" name="clbumName" type="text" placeholder="考生考号"
            :rules="[{ required: true, message: '请填写考生考号' }]" />
        </van-dialog>
      </div>
    </div>
    <!--成绩修改显示-->
    <div v-if="state.page_show == 1" class="van-cell">
      <div class="van-cell__title">
        <span>考生号：</span>
      </div>
      <div class="van-cell__value">
        {{ state.num }}
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>所在考点：</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.school_name }}
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>所在班级：</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.clbum_name }}
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>考生姓名:</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.name }}<span v-if="state.student.sex == '0'">(男)</span><span
          v-if="state.student.sex == '1'">(女)</span>
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>报名序号:</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.num }}
      </div>
    </div>
    <!-- <div class="van-cell">
      <div class="van-cell__title">
          <span>检测项目:</span>
      </div>
      <div class="van-cell__value">
          {{state.info.exam_name}}
      </div>
  </div> -->
  </van-cell-group>
  <van-form @submit="onSubmit" :submit-on-enter="false">
    <van-field v-model="state.info.exam_name" readonly clickable name="exam_name" label="选择检测" placeholder="点击选择检测 "
      @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
    <van-popup v-model:show="state.showPicker" position="bottom">
      <van-picker :columns="state.columns" @confirm="onConfirm" @change="onChange" @cancel="state.showPicker = false" />
    </van-popup>
    <van-field v-model="state.info.tag" readonly clickable name="tag" label="选择科目" placeholder="点击选择科目 "
      @click="state.showPicker1 = true" :rules="[{ required: true, message: '请选择科目' }]" />
    <van-popup v-model:show="state.showPicker1" position="bottom">
      <van-picker :columns="state.sort" @confirm="onExamConfirm" @cancel="state.showPicker1 = false" />
    </van-popup>

    <van-field v-model="state.info.exam_id" label="考试id" name="exam_id" v-show="false" />

    <van-field v-model="state.student.num" label="考生考号" name="student_num" v-show="false" />
    <van-field v-model="state.info.exam_photo_id" label="试卷图片id" name="exam_photo_id" v-show="false" />

    <van-notice-bar wrapable left-icon="info-o">
      拍摄试卷要求:请使用手机摄像头拍摄试卷虚线下试卷内容;不包含上方考号等信息
    </van-notice-bar>

    <van-field v-model="state.info.path" label="考试照片" name="path" v-show="false" placeholder="考试照片" />
    <br />
    <div style="margin-left:5%;">
      <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" previewSize="120" />
    </div>
    <div style="width:100%;height:100%;">
      <!-- <div style="margin: 10px;">
                <div>
                  <van-button  style="width:100%;"  type="primary" size="normal" native-type="submit">
                    <span >提交</span>
                  </van-button>
                </div>
            </div> -->
      <div style="margin: 10px;">
        <div>
          <router-link :to="{ path: '/dashboard' }">
            <van-button style="width:48%;" plain type="primary" size="normal">
              <span style="color:#555555;">返回主页</span>
            </van-button>
          </router-link>
          <van-button style="width:48%;margin-left:4%" type="primary" size="normal" native-type="submit">
            <span>提交</span>
          </van-button>
        </div>
      </div>

      <div v-if="state.page_show == 0" style="margin: 10px;">
        <van-button plain style="width:100%;" type="primary" @click="saoQrcode">
          <span>扫一扫</span>
        </van-button>
      </div>
    </div>
  </van-form>

  <img v-if="previewImage" :src="previewImage" alt="预览图" style="max-width: 100%; max-height: 100%;">
  <Cropper v-if="cropperVisible" :imagePath="imagePath" fileType="blob" @save="onSave" @cancel="onCancel" />
  <div class="bottom-bg"></div>
</template>
<script>
import { reactive, onMounted, ref, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { studentInfo, getJssdk } from '@/apis/student';
import sHeader from '@/components/Header'
import wechatInterface from "@/utils/wechatInterface";
import { Toast, Dialog } from 'vant';
import { examList } from '@/apis/exam';
import { upload } from '@/apis/user';
import { prefix } from '@/utils/function';
import { list } from "@/apis/school_test";
import { examPhotoInfo, addExamPhoto, updateExamPhoto } from '@/apis/marking.js'
import axios from 'axios'
import fileUtils from "@/utils/photo";
export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const fileList = ref([]);
    const active = ref(0);//备用,本校检测时使用
    const URL = window.URL || window.webkitURL;
    const state = reactive({
      addShow: false,
      showPicker: false,
      showPicker1: false,
      num: '',
      type: 'add',
      info: {
        tag: route.query.tag,
        exam_id: (parseInt(route.query.active)) ? route.query.self_exam_id : route.query.exam_id,//公共考点,本校考点判断.
        exam_name: route.query.exam_name,
      },//提交到后台的数据
      columns: [],
      sort: ['素描', '速写', '色彩'],
      student: {
      },
      page_show: 0,//0 班主任上传试卷,1学生上传试卷
      selfColumns: [],
    });

    const cropper = reactive({
      cropperVisible: false,
      imagePath: '',
      previewImage: null
    });
    onMounted(async () => { //微信扫描调用
      axios.defaults.baseURL = `${process.env.VUE_APP_HTTP}/api/v1`//请求阅卷系统地址不一样;需要重新进行赋值
      state.exam_list = await examList({ search: 1 });//获取所有检测数据
      for (let i = 0; i < state.exam_list.length; i++) {
        state.columns.push(state.exam_list[i]);
      }
      // initExam();
      if (Object.keys(route.query).indexOf('active') != -1) {//获取tabs标签
        active.value = parseInt(route.query.active);
      }//indexOf可以判断数组是否包含某个值，返回该值所对应的下标，对于不存在的值，返回 -1

      if (Object.keys(route.query).indexOf('getStudentInfo') == -1) { //班主任,主考修改成绩标识.
        state.jssdk = await getJssdk({ url: window.location.href });
        saoQrcode();
      } else {//直接获取当期考生信息
        state.num = route.query.student_num;
        state.page_show = 1;
        onGetStudent()
      }
    })

    const onSave = async (res) => {
      axios.defaults.baseURL = `${process.env.VUE_APP_HTTP}/api/v1`//请求阅卷系统地址不一样;需要重新进行赋值
      let file = new window.File([res], state.student.num + '.jpg', { type: res.type })//blob 转file对象
      Toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0
      });

      let date = new Date();
      let formdata = new FormData();
      let newImg = await fileUtils.compressorImage(file, 'file', 0.8);//压缩比例,0.8 46.41%,0.8 67.99%
      formdata.append("file", newImg);
      let path = `exam_photo/${state.info.subject}${date.getFullYear()}_${state.info.exam_id}/${state.student.num}`;//state.info.subject+2022+'_'+state.info.exam_id
      formdata.append("zipping", 0);
      formdata.append("path", path);
      fileList.value = [];
      let updateData = await upload(formdata);//上传原图.不压缩.
      fileList.value.splice(0, 5);
      let q = new Date().getTime();
      fileList.value.push({ url: `${prefix(updateData)}?q=${q}` });
      state.info.path = updateData;
      cropper.cropperVisible = false
      Toast('上传成功');
    };

    const onCancel = () => {
      cropper.cropperVisible = false
    };
    const isShow = () => {
      state.addShow = true;
    }
    const onClickTab = () => {
      state.info = {};
      fileList.value = [];//清空图片

      state.showPicker = false;
      state.showPicker1 = false;
    };//tabs 标签点击事件
    const saoQrcode = () =>//微信扫一扫
    {
      wechatInterface(
        state.jssdk,
        async (res) => {// 扫描成功回调
          state.num = res.resultStr;//学生考号
          onGetStudent();//查询考生信息
        },
        (errRes) => {// 扫描失败回调
          console.log(errRes);
          Dialog.alert({
            title: '提示',
            message: '扫描接口调用失败,请刷新页面重试.',
            theme: 'round-button',
          }).then(() => {
          });
        },
        {
          type: 'qrcode'
        },
      );
    }
    //获取考生信息
    const onGetStudent = async () => {
      axios.defaults.baseURL = `${process.env.VUE_APP_HTTP}/api/v1`//请求阅卷系统地址不一样;需要重新进行赋值
      const params = { num: state.num, type: 'uploadExam' };
      state.student = await studentInfo(params);
      state.selfColumns = await list({ school_id: state.student.school_id, search: 1 });
      fileList.value = [];
      onGetExamInfo();//信息读取
      Toast('查询成功');
    }
    //选择检测确定后操作
    const onConfirm = (value) => {//每次检测的值改变,同时改变科目的值
      if (Object.keys(state.student).length == 0) {//选择完成查询考生成绩
        Toast('请先扫描或填入考生考号');
        return;
      }
      state.info.exam_id = value.id;
      state.info.exam_name = value.text;
      state.value = value.text;

      state.info.tag = ''//科目需要重新选择....
      fileList.value = [];//清空图片

      state.showPicker = false;
    };
    //上传图片操作
    const afterRead = async (file) => { // 此时可以自行将文件上传至服务器

      if (!state.info.exam_id || !state.info.tag) {
        Toast('请先选择检测或科目');
      } else {
        cropper.imagePath = URL.createObjectURL(file.file);
        cropper.cropperVisible = true
      }
      // Toast.loading({
      //     message: '上传中...',
      //     forbidClick: true,
      //     duration:0
      //   });
      //   console.log(file,'file');
      //   console.log(file.file,'file.file');
      //   let date = new Date();
      //   let formdata = new FormData();
      //   let newImg = await fileUtils.compressorImage(file.file, 'file',0.8);//压缩比例,0.8 46.41%,0.8 67.99%
      //   formdata.append("file",newImg);
      //   let path = `exam_photo/${state.info.subject}${date.getFullYear()}_${state.info.exam_id}/${state.student.num}`;//state.info.subject+2022+'_'+state.info.exam_id
      //   formdata.append("zipping",0);
      //   formdata.append("path",path);
      //   console.log(path);
      //   let updateData = await upload(formdata);//上传原图.不压缩.
      //   Toast('上传成功');
      //   console.log(updateData);
      //   state.info.path = updateData;
    };

    //选择科目后操作
    const onExamConfirm = async (value) => {
      state.info.tag = value;
      state.showPicker1 = false;
      if (typeof state.info.exam_id == "undefined") {
        Toast('请选择检测');
      } else {
        if (Object.keys(state.student).length == 0) {//选择完成查询考生成绩
          Toast('请先扫描或填入考生考号');
          return;
        } else {
          onGetExamInfo();//信息读取
        }
      }
    };
    //成绩信息读取
    const onGetExamInfo = async () => {
      ontagDis();//科目变成英文标识
      axios.defaults.baseURL = process.env.VUE_APP_HTTP//请求阅卷系统地址不一样;需要重新进行赋值
      if (typeof state.info.exam_id != "undefined" && typeof state.info.tag != "undefined") {
        fileList.value = [];//清空图片
        let params = {
          exam_id: state.info.exam_id,
          tag: state.info.subject,
          student_num: state.student.num,
        };
        let r = await examPhotoInfo(params);
        if (typeof r !== "undefined") {
          state.info.path = r.path;
          state.info.exam_photo_id = r.id;
          if (state.info.path) {
            if (fileList.value.length == 0) {
              let q = new Date().getTime();
              fileList.value.push({ url: `${prefix(state.info.path)}?q=${q}` });
            }
          }
          state.type = 'update';//改为更新考试试卷
        } else {
          state.info.path = '';
          fileList.value = [];
          state.type = 'add';//改为添加考试试卷
        }
      }
      axios.defaults.baseURL = `${process.env.VUE_APP_HTTP}/api/v1`//请求阅卷系统地址不一样;需要重新进行赋值
    }
    //提交到后台操作
    const onSubmit = async (values) => {
      ontagDis();//科目变成英文标识
      axios.defaults.baseURL = process.env.VUE_APP_HTTP//请求阅卷系统地址不一样;需要重新进行赋值
      Toast.loading({
        message: '请稍等...',
        forbidClick: true,
        duration: 0
      });
      values.open_id = localStorage.getItem('open_id');
      console.log(values);
      values.tag = state.info.subject;
      let updateValues = {//更新数据....
        exam_photo_id: values.exam_photo_id,
        tag: state.info.subject,
        student_num: values.student_num,
        exam_id: state.info.exam_id,
        path: values.path,
        identity: state.page_show
      }
      state.type == 'add' ? await addExamPhoto(values) : await updateExamPhoto(updateValues);
      Toast('提交成功');
      axios.defaults.baseURL = `${process.env.VUE_APP_HTTP}/api/v1`//请求阅卷系统地址不一样;需要重新进行赋值
      if (Object.keys(route.query).indexOf('getStudentInfo') == -1) {//班主任跳着
        router.push({ path: "blank-page", query: { url: 'upload-exam', exam_id: state.info.exam_id, exam_name: state.info.exam_name, tag: state.info.tag, active: active.value } });
      } else {//学生跳转
        router.push({ path: "blank-page", query: { url: 'upload-exam', exam_id: state.info.exam_id, exam_name: state.info.exam_name, tag: state.info.tag, active: active.value, getStudentInfo: 1, student_num: values.student_num } });
      }
    };
    const ontagDis = () => {//科目传递参数处理
      switch (state.info.tag) {//传递到后台修改.....
        case '素描':
          state.info.subject = 'the_sketch';
          break;
        case '速写':
          state.info.subject = 'sketch';
          break;
        case '色彩':
          state.info.subject = 'color';
          break;
      }
    }
    //返回上一页
    const back = () => {
      router.push({ path: "/", query: { out: 1 } });
    }
    return {
      ...toRefs(cropper),
      state,
      fileList,
      back,
      isShow,
      afterRead,
      onSubmit,
      saoQrcode,
      onGetStudent,
      onExamConfirm,
      onConfirm,
      onClickTab,
      ontagDis,
      active,
      onSave,
      onCancel
    };
  },
};
</script>
<style scoped>
.font-color {
  color: #555555;
}

.van-row {
  border-bottom: 1px solid #EBEBEB;
}

.van-col {
  height: 4em;
  line-height: 4em;
}

.bottom-bg {
  height: 0.9rem;
  width: 100%;
  /* background-color: #F6F6F6; */
  border: none;
}

.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 9em;
}

p {
  color: #747474
}

.clbum-info {
  float: left;
  margin-left: 5%;
  margin-top: 7px;
  font-size: 13px;
  width: 80%;
}

h1 {
  float: left;
  margin-top: 25px;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>