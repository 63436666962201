
import axios from '../utils/axios'

export function list(query) {//考点详情
    return axios.get(`/school_test`,{params:query})
}
export function add(params)
{
    return axios.post(`/school_test`,params)
}
export function del(params)
{
    return axios.delete(`/school_test`,{data:params})
}

export function update(params)
{
    return axios.put(`/school_test`,params)
}

export function info(query)
{
    return axios.get(`/school_test/show`,{params:query})
}


export function openFen(params)
{
    return axios.put(`/school_test/open_fen`,params)
}

// export function delClbum(id) {
//     return axios.delete(`/clbum/${id}`);
// }