import CryptoJS from "crypto-js";
let KEY = 'ZZWBKJ_ZHIHUAWEI';
let IV = '123456789111111+';
// 加密函数
export let encrypt= (str)=>{
  var key = CryptoJS.enc.Utf8.parse(KEY);
  var iv = CryptoJS.enc.Utf8.parse(IV);
  var encrypted = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}
//解密
export let decrypt=(str) => {
        let key = CryptoJS.enc.Utf8.parse(KEY);
        let iv = CryptoJS.enc.Utf8.parse(IV);
        let decrypt = CryptoJS.AES.decrypt(str, key, { 
        iv:iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
}

