
import axios from '../utils/axios'

export function studentList(query) {//考点详情
    console.log({params:query});
    return axios.get(`/student`,{params:query})
}
export function addStudent(params)
{
    return axios.post(`/student`,params)
}
export function delStudent(params)
{
    return axios.delete(`/student`,{data:params})
}

export function updateStudent(params)
{
    return axios.put(`/student`,params)
}

export function updatePhoto(params)
{
    return axios.put(`/student/update_photo`,params)
}

export function getJssdk(query)
{
    return axios.get(`/user/get_jssdk`,{params:query})
}

export function studentInfo(query)
{
    return axios.get(`/student/show`,{params:query})
}

export function forgetPassword(query)
{
    return axios.get(`/student/forget_password`,{params:query})
}

//阶段目标......
export function stageGoalList()
{
    return axios.get(`/stage_goal`,)
}
//添加阶段目标
export function addStageGoal(params)
{
    return axios.post(`/stage_goal`,params)
}
//编辑阶段目标
export function updateStageGoal(params)
{
    return axios.put(`/stage_goal`,params)
}
//查询阶段目标
export function stageGoalInfo(query)
{
    return axios.get(`/stage_goal/show`,{params:query})
}

//最终目标
export function UltimateGoalInfo(query)
{
    return axios.get(`/ultimate_goal/show`,{params:query})
}
//编辑最终目标
export function updateUltimateGoal(params)
{
    return axios.put(`/ultimate_goal`,params)
}
//新增最终目标
export function addUltimateGoal(params)
{
    return axios.post(`/ultimate_goal`,params)
}



// export function delClbum(id) {
//     return axios.delete(`/clbum/${id}`);
// }