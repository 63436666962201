
import axios from '../utils/axios'


export function addFeedback(params)
{
    return axios.post(`/feedback`,params)
}

// export function delClbum(id) {
//     return axios.delete(`/clbum/${id}`);
// }