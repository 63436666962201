<template>
  <s-header :name="'-成绩管理'"></s-header>
  <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
  <van-form @submit="onSubmit" :submit-on-enter="false">

    <van-tabs v-model:active="active" @click="onClickTab">
      <van-tab title="公共检测">
        <van-field v-model="state.examValue" readonly clickable label="检测" placeholder="点击选择检测 "
          @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
        <van-popup v-model:show="state.showPicker" position="bottom">
          <van-picker :columns="state.examPicker" @confirm="onConfirm" @cancel="state.showPicker = false" />
        </van-popup>
      </van-tab>
      <van-tab title="本校检测">
        <van-field v-model="state.examValue" readonly clickable label="检测" placeholder="点击选择检测 "
          @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
        <van-popup v-model:show="state.showPicker" position="bottom">
          <van-picker :columns="state.selfColumns" @confirm="onConfirm" @cancel="state.showPicker = false" />
        </van-popup>
      </van-tab>
    </van-tabs>

    <div v-if="state.identity == 'school'">
      <van-field v-model="state.clbumValue" readonly clickable label="班级" placeholder="点击选择班级 "
        @click="state.showPicker1 = true" :rules="[{ required: true, message: '请选择班级' }]" />

      <van-popup v-model:show="state.showPicker1" position="bottom">
        <van-picker :columns="state.clbumPicker" @confirm="onConfirmClbum" @cancel="state.showPicker1 = false" />
      </van-popup>
    </div>
    <van-field v-model="state.sortValue" readonly clickable label="排序方式" placeholder="点击排序方式 "
      @click="state.showPicker2 = true" :rules="[{ required: true, message: '请选择排序方式' }]" />
    <van-popup v-model:show="state.showPicker2" position="bottom">
      <van-picker :columns="state.sortPicker" @confirm="onConfirmSort" @cancel="state.showPicker2 = false" />
    </van-popup>

    <van-field v-model="state.info.student_name" name="student_name" maxlength="6" label="考生姓名" placeholder="请填写考生姓名" />

    <van-field v-model="state.info.exam_id" label="检测id" name="exam_id" v-show="false" />

    <van-field v-model="state.info.clbum_id" label="班级id" name="clbum_id" v-show="false" />

    <van-field v-model="state.info.sort" label="班级id" name="sort" v-show="false" />

    <div style="width: 100%; height: 100%; background: #f6f6f6">
      <!-- <van-notice-bar  left-icon="info-o">
            技术是开发它的人的共同灵魂。
        </van-notice-bar> -->
      <div style="margin: 10px">
        <div>
          <router-link :to="{ path: '/dashboard' }">
            <van-button style="width: 48%" plain type="primary" size="normal">
              <span style="color: #555555">返回主页</span>
            </van-button>
          </router-link>
          <!-- <van-button  style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
              <span >发送到邮箱</span>
            </van-button> -->
          <van-button style="width: 48%; margin-left: 4%" type="primary" size="normal" native-type="submit">
            <span>查询</span>
          </van-button>
        </div>
      </div>

      <div class="van-cell">
        <div class="van-cell__title">
          <span>总分:{{ state.list.avg.total_fen }} 素描:{{
            state.list.avg.the_sketch
          }}
            速写:{{ state.list.avg.sketch }} 色彩:{{
              state.list.avg.color
            }}</span>
          <!-- <a href="https://bm.yszytb.com/uploads/models/%E8%80%83%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx" target="_blank">下载成绩</a> -->
        </div>
      </div>

      <div v-for="(item, index) in state.list.list" :key="index" class="demo-preview">
        <div style="margin-left: 5%">
          <h1>{{ index + 1 }}</h1>
          <!-- <h1>{{item.id}}</h1> -->
          <div class="clbum-info">
            <h3>
              {{ item.student_name }}--{{ item.clbum_name }}--{{
                item.exam_name
              }}
            </h3>
            <p>
              总分:{{ item.total_fen }} 素描:{{ item.the_sketch }} 速写:{{
                item.sketch
              }}
              色彩:{{ item.color }}
            </p>
          </div>
        </div>
        <!-- 只有主考可以修改考生成绩 -->
        <div v-if="state.identity == 'school'" class="clbum-share">
          <van-button type="primary" style="margin-right: 10px"
            @click="saveUrl(item.student_num, item.exam_id, item.self_exam_id, item.exam_name)" plain
            size="small">修改</van-button>
        </div>
      </div>
      <br />
    </div>
  </van-form>

  <div class="bottom-bg"></div>
</template>
<script>
import { reactive, onMounted, ref } from "vue";
import { useRouter, useRoute } from 'vue-router';
import sHeader from "@/components/Header";
import { examList } from "@/apis/exam";
import { clbumList } from "@/apis/clbum.js";
import { Toast } from "vant";
import { gradeList } from "@/apis/grade.js";
import { getLocalUser } from "@/utils/function";
import { list } from "@/apis/school_test";
export default {
  components: {
    sHeader,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const active = ref(0);
    const state = reactive({
      user: getLocalUser("user"),
      identity: getLocalUser("user").identity,
      examPicker: [],
      clbumPicker: [],
      sortPicker: [
        { id: "total_fen", text: "总分" },
        { id: "the_sketch", text: "素描" },
        { id: "sketch", text: "速写" },
        { id: "color", text: "色彩" },
      ],
      examValue: "",
      clbumValue: "",
      sortValue: "总分",
      showPicker: false,
      showPicker1: false,
      showPicker2: false,
      info: {}, //提交的参数
      list: {
        avg: {
          total_fen: "",
          the_sketch: "",
          sketch: "",
          color: "",
        },
      }, //最终数据
      selfColumns: [],
    });
    onMounted(async () => {
      //微信扫描调用
      state.exam_list = await examList({ search: 1 }); //获取所有检测数据
      if (Object.keys(route.query).indexOf('exam_id') != -1) {
        state.examValue = state.exam_list[route.query.exam_id - 1]["text"];
        state.info.exam_id = state.exam_list[route.query.exam_id - 1]["id"];////indexOf可以判断数组是否包含某个值，返回该值所对应的下标，对于不存在的值，返回 -1
      } else {
        state.examValue = state.exam_list[0]["text"];
        state.info.exam_id = state.exam_list[0]["id"]
      }

      for (let i = 0; i < state.exam_list.length; i++) {
        console.log(state.exam_list[i]);
        state.examPicker.push(state.exam_list[i]);
      }

      if (state.user.identity == 'school') {
        state.selfColumns = await list({ school_id: state.user.id, search: 1 });
      } else {
        state.selfColumns = await list({ school_id: state.user.school_id, search: 1 });
      }

      state.clbum_list = await clbumList({ search: 1 }); //获取所有检测数据
      state.clbumPicker.push({ id: "0", text: "全校" });
      state.clbumValue = "全校";

      if (state.identity == "clbum") {
        console.log("user", state.user);
        state.info.clbum_id = state.user.id;
      }

      for (let i = 0; i < state.clbum_list.length; i++) {
        console.log(state.clbum_list[i]);
        state.clbumPicker.push(state.clbum_list[i]);
      }
      state.info.sort = "total_fen";
    });

    const onClickTab = () => {//tabs切换事件
      console.log(active.value);
      if (active.value) {
        state.examValue = state.selfColumns[0]["text"];
        state.info.exam_id = state.selfColumns[0]["id"];
      } else {
        state.examValue = state.examPicker[0]["text"];
        state.info.exam_id = state.examPicker[0]["id"];
      }
    };//tabs 标签点击事件

    const onConfirm = (value) => {//考试选择事件
      state.examValue = value.text;
      state.info.exam_id = value.id;
      state.showPicker = false;
    };

    const onConfirmClbum = (value) => {//班级选择事件
      state.clbumValue = value.text;
      state.info.clbum_id = value.id;
      state.showPicker1 = false;
    };

    const onConfirmSort = (value) => {//排序选择事件
      state.sortValue = value.text;
      state.info.sort = value.id;
      state.showPicker2 = false;
    };

    const onSubmit = async (values) => {
      values.active = active.value;
      values.exam_id = active.value ? '' : state.info.exam_id;//acive=0 录入公共检测字段
      values.self_exam_id = active.value ? state.info.exam_id : '';//acive=0 录入本校检测字段字段
      console.log('values', values);
      Toast.loading({
        message: "请稍等...",
        forbidClick: true,
        duration: 0
      });
      state.list = await gradeList(values);
      Toast.clear();
    };

    const saveUrl = (student_num, exam_id, self_exam_id, exam_name) => {
      router.push({ path: '/grade-record', query: { student_num: student_num, exam_id: exam_id, self_exam_id: self_exam_id, exam_name: exam_name, savaGrade: 1, active: active.value } })
    }

    return {
      state,
      onSubmit,
      onConfirm,
      onConfirmClbum,
      onConfirmSort,
      saveUrl,
      active,
      onClickTab,
    };
  },
};
</script>
<style  scoped>
.font-color {
  color: #555555;
}

.van-row {
  border-bottom: 1px solid #ebebeb;
}

.van-col {
  height: 4em;
  line-height: 4em;
}

.bottom-bg {
  height: 0.9rem;
  width: 100%;
  /* background-color: #F6F6F6; */
  border: none;
}

.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 12em;
}

p {
  color: #747474;
}

.clbum-info {
  float: left;
  margin-left: 5%;
  margin-top: 7px;
  font-size: 13px;
  width: 80%;
}

h1 {
  float: left;
  margin-top: 25px;
}

h1 {
  float: left;
  margin-top: 25px;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>